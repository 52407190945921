import { Button, Menu, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";

import ActionItem from "../../types/ActionItem";

type Props = {
  actions?: ActionItem[];
};

export default function ButtonMenu(props: Props) {
  return (
    <Menu
      transition="fade"
      position='bottom-end'
      width={220}
      withinPortal
    >
      <Menu.Target>
        <Button rightIcon={<IconChevronDown size={18} stroke={1.5} />} pr={12}>
          Actions
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {props.actions?.map((action, index) => (
          <Menu.Item
            key={index}
            icon={action.icon}
            rightSection={
              <Text size='xs' transform='uppercase' weight={700} color='dimmed'>
                {action.shortcut}
              </Text>
            }
          >
            {action.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
