import { Paper, SimpleGrid, Skeleton, Text } from "@mantine/core";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

import getOrganization from "../api/getOrganization";
import LoaderData from "../types/LoaderData";
import GetOrgRes from "../types/responses/GetOrgRes";

export const loader = async () => {
  try {
    const orgPromise = getOrganization();
    return defer({ body: orgPromise });
  } catch (error) {
    return { body: { title: "error" } };
  }
};

type DataDisplayProps = {
  title: string;
  value: string | number;
};

const DataDisplay = ({ title, value }: DataDisplayProps) => (
  <div style={{ padding: ".5rem 0" }}>
    <Text fw={700}>{title}</Text>
    <Text>{value}</Text>
  </div>
);

const OrgSkeleton = () => (
  <Paper shadow='xs' p='xs'>
    <SimpleGrid
      cols={3}
      breakpoints={[
        { maxWidth: "sm", cols: 1 },
        { maxWidth: "md", cols: 2 },
      ]}
    >
      {[...Array(6)].map((_, i) => (
        <div key={i} style={{ padding: ".5rem 0" }}>
          <Skeleton height={8} mb='sm' />
          <Skeleton height={8} />
        </div>
      ))}
    </SimpleGrid>
  </Paper>
);

const Organizations = () => {
  const data = useLoaderData() as LoaderData<GetOrgRes>;

  return (
    <Suspense fallback={<OrgSkeleton />}>
      <Await resolve={data.body}>
        {(resolvedData: GetOrgRes) => {
          return (
            <Paper shadow='xs' p='xs'>
              <SimpleGrid
                cols={3}
                breakpoints={[
                  { maxWidth: "sm", cols: 1 },
                  { maxWidth: "md", cols: 2 },
                ]}
              >
                <DataDisplay
                  title={"Root Email"}
                  value={resolvedData.rootEmail}
                />
                <DataDisplay
                  title={"Account Id"}
                  value={resolvedData.managementAccount.accountId}
                />
                <DataDisplay
                  title={"Partition Id"}
                  value={resolvedData.managementAccount.partitionId}
                />
                <DataDisplay
                  title={"Accounts"}
                  value={resolvedData.childCount.accounts}
                />
                <DataDisplay
                  title={"SCPs"}
                  value={resolvedData.childCount.scps}
                />
                <DataDisplay
                  title={"OUs"}
                  value={resolvedData.childCount.orgUnits}
                />
              </SimpleGrid>
            </Paper>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default Organizations;
