import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

import getScps from "../api/getScps";
import { DataTable } from "../components/data/DataTable";
import SkeletonTable from "../components/skeletons/SkeletonTable";
import LoaderData from "../types/LoaderData";
import GetScpRes from "../types/responses/GetScpRes";

export const loader = async () => {
  try {
    const responsePromise: Promise<GetScpRes> = getScps();
    return defer({ body: responsePromise });
  } catch (error) {
    throw new Error(`Loader issue ${error}`);
  }
};

const SCPs = () => {
  const data = useLoaderData() as LoaderData<GetScpRes>;
  const headers: string[] = ["Name", "Description"];

  return (
    <Suspense fallback={<SkeletonTable columns={headers.length} rows={2} />}>
      <Await resolve={data.body}>
        {(resolvedData: GetScpRes) => {
          const rows = resolvedData.scps.map((scp, i) => (
            <tr key={i}>
              <td>{scp.name}</td>
              <td>{scp.description}</td>
            </tr>
          ));

          return <DataTable headers={headers} rows={rows} />;
        }}
      </Await>
    </Suspense>
  );
};

export default SCPs;
