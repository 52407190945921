import { Authenticator } from "@aws-amplify/ui-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import Trek10ThemeProvider from "./assets/theme/Trek10ThemeProvider";
import "./index.scss";
import router from "./routing/Router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Trek10ThemeProvider theme={{ colorScheme: "dark" }}>
        <RouterProvider router={router} />
      </Trek10ThemeProvider>
    </Authenticator.Provider>
  </React.StrictMode>,
);
