import { Group, Switch } from "@mantine/core";
import dagre from "dagre";
import { useEffect, useState } from "react";
import ReactFlow, {
  Background,
  ConnectionLineType,
  Controls,
  Edge,
  Node,
  NodeTypes,
  Position,
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useReactFlow,
} from "reactflow";

import GetOrgHierarchyRes from "../../types/responses/GetOrgHierarchyRes";
import AccountVis from "../../types/visualizer/AccountVis";
import OrgUnitVis from "../../types/visualizer/OrgUnitVis";
import StackVis from "../../types/visualizer/StackVis";
import AccountNode from "./nodes/AccountNode";
import OrgUnitNode from "./nodes/OrgUnitNode";
import RootNode from "./nodes/RootNode";
import StackNode from "./nodes/StackNode";

const nodeTypes: NodeTypes = {
  root: RootNode,
  ou: OrgUnitNode,
  account: AccountNode,
  stack: StackNode,
};

const getNode = <T,>(type: string, id: string, label: string, data?: T) => ({
  id: id,
  position: { x: 0, y: 0 },
  data: {
    label: label,
    ...data,
  },
  type: type,
});

const getEdge = (source: Node, target: Node): Edge => ({
  id: `e${source.id}-${target.id}`,
  source: source.id,
  target: target.id,
  type: "smoothstep",
});

const getRootNode = (id: string): Node =>
  getNode("root", id, "Root", { root: { id } });
const getOUNode = (id: string, name: string): Node =>
  getNode("ou", id, name, { orgUnit: { name, id } });
const getAccountNode = (id: string, name: string, partitionId: string): Node =>
  getNode("account", id, name, { account: { name, id, partitionId } });
const getStackNode = (accountId: string, name: string, region: string): Node =>
  getNode("stack", `${accountId}-${name}`, name, { stack: { name, region } });

// -- dagre

const nodeWidth = 300;
const nodeHeight = 36;

const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction = "LR",
) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = Position.Left;
    node.sourcePosition = Position.Right;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const handleStacks = (
  source: Node,
  stacks: StackVis[],
  accountId: string,
  nodes: Node[],
  edges: Edge[],
) => {
  stacks.forEach((stack) => {
    const node = getStackNode(accountId, stack.name, stack.region);
    const edge = getEdge(source, node);

    nodes.push(node);
    edges.push(edge);
  });
};

const handleAccounts = (
  source: Node,
  accounts: AccountVis[],
  nodes: Node[],
  edges: Edge[],
  includeStacks = false,
) => {
  accounts.forEach((account) => {
    const node = getAccountNode(account.id, account.name, account.partitionId);
    const edge = getEdge(source, node);

    nodes.push(node);
    edges.push(edge);

    if (includeStacks) {
      handleStacks(node, account.stacks, account.id, nodes, edges);
    }
  });
};

const handleOUs = (
  source: Node,
  orgUnits: OrgUnitVis[],
  nodes: Node[],
  edges: Edge[],
  includeAccounts: boolean,
) => {
  orgUnits.forEach((ou) => {
    const node = getOUNode(ou.id, ou.name);
    const edge = getEdge(source, node);

    nodes.push(node);
    edges.push(edge);

    handleOUs(node, ou.orgUnits, nodes, edges, includeAccounts);
    if (includeAccounts) {
      handleAccounts(node, ou.accounts, nodes, edges);
    }
  });
};

type Options = {
  showOrgUnits: boolean;
  showAccounts: boolean;
  showStacks: boolean;
};

type Props = {
  res: GetOrgHierarchyRes;
};

const OrgChartFlow = ({ res }: Props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [options, setOptions] = useState<Options>({
    showOrgUnits: true,
    showAccounts: true,
    showStacks: false,
  });

  const { fitView } = useReactFlow();
  const nodesInitialized = useNodesInitialized();

  useEffect(() => {
    const createLayout = (res?: GetOrgHierarchyRes) => {
      const newNodes: Node[] = [];
      const newEdges: Edge[] = [];

      if (res?.root) {
        const rootNode = getRootNode(res.root.id);
        newNodes.push(rootNode);

        if (options.showOrgUnits) {
          handleOUs(
            rootNode,
            res.root.orgUnits,
            newNodes,
            newEdges,
            options.showAccounts,
          );
        }
        if (options.showAccounts) {
          handleAccounts(
            rootNode,
            res.root.accounts,
            newNodes,
            newEdges,
            options.showStacks,
          );
        }
      }
      const { nodes: resNodes, edges: resEdges } = getLayoutedElements(
        newNodes,
        newEdges,
      );
      setNodes(resNodes);
      setEdges(resEdges);
    };

    createLayout(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res, options]);

  useEffect(() => {
    if (nodesInitialized) {
      fitView({ duration: 500 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes]);

  return (
    <div style={{ width: "calc(100%)", height: "calc(90%)" }}>
      <Group>
        <Switch
          label={"Org Units"}
          checked={options.showOrgUnits}
          onChange={(event) =>
            setOptions((prev: Options) => ({
              ...prev,
              showOrgUnits: event.target.checked,
            }))
          }
        />
        <Switch
          label={"Accounts"}
          checked={options.showAccounts}
          onChange={(event) =>
            setOptions((prev: Options) => ({
              ...prev,
              showAccounts: event.target.checked,
            }))
          }
        />
        <Switch
          label={"Stacks"}
          checked={options.showStacks}
          onChange={(event) =>
            setOptions((prev: Options) => ({
              ...prev,
              showStacks: event.target.checked,
            }))
          }
        />
      </Group>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
        connectionLineType={ConnectionLineType.Straight}
        style={{ border: "solid 1px black" }}
      >
        {/* <MiniMap /> */}
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default OrgChartFlow;
