import { Skeleton } from "@mantine/core";

import { DataTable } from "../data/DataTable";

type Props = {
  columns: number;
  rows?: number;
};

const SkeletonTable = ({ columns, rows = 4 }: Props) => {
  const skeletonRows = [...Array(rows)].map((_, i) => (
    <tr key={i}>
      {[...Array(columns)].map((_, i) => (
        <td key={i}>
          <Skeleton height={8} my='xs' />
        </td>
      ))}
    </tr>
  ));

  return <DataTable headers={Array(columns).fill("...")} rows={skeletonRows} />;
};

export default SkeletonTable;
