import { NavLink, Navbar, createStyles } from "@mantine/core";
import { IconLogout } from "@tabler/icons";
import { Auth } from "aws-amplify";
import { NavLink as Link } from "react-router-dom";

import NavItem from "../../types/RouteItem";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon: string = getRef("icon");

  return {
    header: {
      marginBottom: theme.spacing.lg,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.gray[2]
      }`,
    },
    brandLink: {
      textDecoration: "none",
      color: "inherit",
      "&:hover": {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
    brand: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      justifyContent: "center",
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        "&": {
          display: "none",
        },
      },
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[1]
          : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor:
          theme.colorScheme === "dark"
            ? theme.colors.dark[6]
            : theme.colors.gray[0],
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },

      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        "& *": {
          margin: "0 !important",
        },
        "& > :first-of-type + span": {
          display: "none",
        },
      },
    },

    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: theme.fn.variant({
          variant: "light",
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: "light",
            color: theme.primaryColor,
          }).color,
        },
      },
    },
    routerLink: {
      textDecoration: "none",
    },
  };
});

type Props = {
  navItems?: NavItem[];
};

export default function NavbarSimple(props: Props) {
  const { classes } = useStyles();

  const signOut = async () => await Auth.signOut();

  return (
    <Navbar
      width={{
        base: 81,
        sm: 250,
      }}
      miw={"fit-content"}
      py={"md"}
    >
      <Navbar.Section grow px='md'>
        {props.navItems?.map((item, index) => (
          <Link to={item.path} key={index} className={classes.routerLink} end>
            {({ isActive }) => (
              <NavLink
                className={classes.link}
                label={item.label}
                description={item.description}
                icon={item.icon}
                active={isActive}
              />
            )}
          </Link>
        ))}
      </Navbar.Section>

      <Navbar.Section pt='md' px='md'>
        <a className={classes.link} onClick={signOut}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Logout</span>
        </a>
      </Navbar.Section>
    </Navbar>
  );
}
