import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

import getAccounts from "../api/getAccounts";
import { DataTable } from "../components/data/DataTable";
import SkeletonTable from "../components/skeletons/SkeletonTable";
import LoaderData from "../types/LoaderData";
import GetAccountsRes from "../types/responses/GetAccountsRes";

export const loader = async () => {
  try {
    const responsePromise: Promise<GetAccountsRes> = getAccounts();
    return defer({ body: responsePromise });
  } catch (error) {
    throw new Error(`Loader issue ${error}`);
  }
};

const Accounts = () => {
  const data = useLoaderData() as LoaderData<GetAccountsRes>;
  const headers: string[] = ["Account Name", "Account Alias", "Email"];

  return (
    <Suspense fallback={<SkeletonTable columns={headers.length} />}>
      <Await resolve={data.body}>
        {(resolvedData: GetAccountsRes) => {
          const rows = resolvedData.accounts.map((account, i) => (
            <tr key={i}>
              <td>{account.name}</td>
              <td>{account.alias}</td>
              <td>{account.email}</td>
            </tr>
          ));

          return <DataTable headers={headers} rows={rows} />;
        }}
      </Await>
    </Suspense>
  );
};

export default Accounts;
