import {
  Box,
  Group,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  createStyles,
} from "@mantine/core";
import {
  IconBuildingSkyscraper,
  IconGitFork,
  IconLock,
  IconUserSearch,
  IconUsers,
} from "@tabler/icons";
import { Suspense } from "react";
import { Await, Link, useLoaderData } from "react-router-dom";

import DisplayCard from "../components/cards/index/DisplayCard";
import { PageOverviewCard } from "../components/cards/index/PageOverviewCard";
import LoaderData from "../types/LoaderData";
import GetOrgRes from "../types/responses/GetOrgRes";

export const useStyles = createStyles((theme, _params, getRef) => {
  const icon: string = getRef("icon");
  return {
    orgCard: {
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        gridArea: "1 / 1 / 3 / 2",
      },
    },
    dataCards: {
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        gridArea: " 1 / 2 / 4 / 3",
      },
    },
    yourInfoCard: {
      [`@media (min-width: ${theme.breakpoints.md}px)`]: {
        gridArea: "3 / 1 / 4 / 2",
      },
    },
    accountCard: {
      flexGrow: 1,
    },
    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      "&:hover": {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },
  };
});

const OrgSkeleton = () => (
  <>
    <Box pb={"sm"}>
      <div>Account Id</div>
      <Skeleton height={12} />
    </Box>
    <Box pb={"sm"}>
      <div>Root Email</div>
      <Skeleton height={12} />
    </Box>
    <div>Stats</div>
    <Skeleton height={12} mb={10} />
    <Skeleton height={12} mb={10} />
    <Skeleton height={12} mb={10} />
  </>
);

const Index = () => {
  const { classes } = useStyles();
  const data = useLoaderData() as LoaderData<GetOrgRes>;

  return (
    <SimpleGrid cols={2} breakpoints={[{ maxWidth: "md", cols: 1 }]}>
      <DisplayCard
        className={classes.orgCard}
        header={
          <DisplayCard.Header
            icon={<IconBuildingSkyscraper stroke={1.5} />}
            title={"Your Organization"}
            link={"/organization"}
          />
        }
        footer={
          <Group position='right'>
            <Link to={"/organization"} className={classes.linkIcon}>
              <Text>{"View Your Organization"}</Text>
            </Link>
          </Group>
        }
      >
        <Suspense fallback={<OrgSkeleton />}>
          <Await resolve={data.body}>
            {(resolvedData: GetOrgRes) => (
              <>
                <Box pb={"sm"}>
                  <Text fw={700}>Account Id</Text>
                  <Text fs='italic'>
                    {resolvedData.managementAccount.accountId}
                  </Text>
                </Box>
                <Box pb={"sm"}>
                  <Text fw={700}>Root Email</Text>
                  <Text fs='italic'>{resolvedData.rootEmail}</Text>
                </Box>
                <Text fw={700}>Stats</Text>
                <Text fs='italic'>
                  Organizational Units: {resolvedData.childCount.orgUnits}
                </Text>
                <Text fs='italic'>
                  Accounts: {resolvedData.childCount.accounts}
                </Text>
                <Text fs='italic'>
                  Service Control Policies: {resolvedData.childCount.scps}
                </Text>
              </>
            )}
          </Await>
        </Suspense>
      </DisplayCard>

      <Stack justify='space-between' className={classes.dataCards}>
        <PageOverviewCard
          desc='Organizational units are used to group accounts together so that they may be administered as a single unit.'
          title='Organizational Units (OUs)'
          linkText='View OUs'
          link='/organizational-units'
          docs={{
            link: "https://docs.aws.amazon.com/organizations/latest/userguide/orgs_manage_ous.html",
            label: "AWS Docs",
          }}
          icon={<IconUsers stroke={1.5} />}
        />
        <PageOverviewCard
          desc='The collection of AWS accounts that together make up your Organization.'
          title='Accounts'
          linkText='View Accounts'
          link='/accounts'
          docs={{
            link: "https://docs.aws.amazon.com/organizations/latest/userguide/orgs_manage_accounts.html",
            label: "AWS Docs",
          }}
          className={classes.accountCard}
          icon={<IconUserSearch stroke={1.5} />}
        />
        <PageOverviewCard
          desc='SCPs are a type of organization policy that you can use to manage permissions in your organization.'
          title='Service Control Policies (SCPs)'
          linkText='View SCPs'
          link='/scps'
          docs={{
            link: "https://docs.aws.amazon.com/organizations/latest/userguide/orgs_manage_policies_scps.html",
            label: "AWS Docs",
          }}
          icon={<IconLock stroke={1.5} />}
        />
      </Stack>

      <PageOverviewCard
        desc='Visualize your organization&#039;s Accounts, Organizations, and Stacks in an interactive node based graph.'
        title='Visualizer'
        linkText='View Visualizer'
        link='/visualizer'
        icon={<IconGitFork stroke={1.5} />}
      />
    </SimpleGrid>
  );
};

export default Index;
