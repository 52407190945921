import { IconBuildingSkyscraper } from "@tabler/icons";
import { Outlet } from "react-router-dom";

import RouteItem from "../../types/RouteItem";
import childRouteItem from "./organization/childRouteItem";
import indexRouteItem from "./organization/indexRouteItem";

const organzationRouteItem: RouteItem = {
  path: "organization",
  label: "My Organization",
  icon: <IconBuildingSkyscraper stroke={1.5} />,
  element: <Outlet />,
  children: [indexRouteItem, childRouteItem],
};

export default organzationRouteItem;
