import { IconGitFork } from "@tabler/icons";

import OrgHierarchy, {
  loader as visualizerLoader,
} from "../../pages/OrgHierarchy";

const orgHierarchyRouteItem = {
  path: "visualizer",
  element: <OrgHierarchy />,
  label: "Visualizer",
  // description: 'Item description',
  icon: <IconGitFork stroke={1.5} />,
  loader: visualizerLoader,
};

export default orgHierarchyRouteItem;
