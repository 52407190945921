import { Box, Card, Group, Text, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

export const useStyles = createStyles((theme, _params, getRef) => {
  const icon: string = getRef("icon");
  return {
    header: {
      borderBottom: "solid 1px rgba(125,125,125,0.5)",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#1A1B1E",
    },
    linkIcon: {
      ref: icon,
      textDecoration: "none",
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      "&:hover": {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },
  };
});

export interface HeaderProps {
  title: string;
  link: string;
  icon: React.ReactNode;
}

export interface DisplayCardProps {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

const Header = ({ link, title, icon }: HeaderProps) => {
  const { classes } = useStyles();

  return (
    <Group position='apart' align={"center"}>
      <Text fw={"700"}>{title}</Text>
      <Link
        style={{ lineHeight: "initial" }}
        to={link}
        className={classes.linkIcon}
      >
        {icon}
      </Link>
    </Group>
  );
};

const DisplayCard = ({
  header,
  children,
  footer,
  className,
}: DisplayCardProps) => {
  const { classes, cx } = useStyles();

  return (
    <Card
      className={cx(classes.card, className)}
      shadow={"sm"}
      p={"xs"}
      radius='md'
    >
      {header && (
        <Card.Section inheritPadding>
          <Box mt={"xs"} mb={"xs"} pb={"3px"} className={classes.header}>
            {header}
          </Box>
        </Card.Section>
      )}
      <Box
        sx={() => ({
          flexGrow: 1,
        })}
      >
        {children}
      </Box>
      {footer && (
        <Card.Section inheritPadding>
          <Box mt={"xs"} mb={"xs"} pt={"3px"}>
            {footer}
          </Box>
        </Card.Section>
      )}
    </Card>
  );
};

DisplayCard.Header = Header;

export default DisplayCard;
