import { NodeProps, Position } from "reactflow";

import AccountVis from "../../../types/visualizer/AccountVis";
import BaseNode from "./BaseNode";

export interface OrgUnitProps extends NodeProps {
  data: {
    account: AccountVis;
  };
}

const AccountNode = (props: OrgUnitProps) => {
  return (
    <>
      <BaseNode
        header='Account'
        label={props.data?.account.name}
        bg={"red"}
        handles={[
          { type: "target", position: Position.Left },
          { type: "source", position: Position.Right },
        ]}
      />
    </>
  );
};

export default AccountNode;
