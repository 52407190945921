import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

import getOrgUnits from "../api/getOrgUnits";
import { DataTable } from "../components/data/DataTable";
import SkeletonTable from "../components/skeletons/SkeletonTable";
import LoaderData from "../types/LoaderData";
import GetOrgUnitRes from "../types/responses/GetOrgUnitRes";

export const loader = async () => {
  try {
    const responsePromise: Promise<GetOrgUnitRes> = getOrgUnits();
    return defer({ body: responsePromise });
  } catch (error) {
    throw new Error("Loader issue");
  }
};

const OrgUnits = () => {
  const data = useLoaderData() as LoaderData<GetOrgUnitRes>;
  const headers: string[] = ["Name", "Parent Id"];

  return (
    <Suspense fallback={<SkeletonTable columns={headers.length} rows={4} />}>
      <Await resolve={data.body}>
        {(resolvedData: GetOrgUnitRes) => {
          const rows = resolvedData.orgUnits.map((ou, i) => (
            <tr key={i}>
              <td>{ou.name}</td>
              <td>{ou.parentId}</td>
            </tr>
          ));

          return <DataTable headers={headers} rows={rows} />;
        }}
      </Await>
    </Suspense>
  );
};

export default OrgUnits;
