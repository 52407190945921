import { API, Auth } from "aws-amplify";

import GetAccountsRes from "../types/responses/GetAccountsRes";

const getAccounts = async (): Promise<GetAccountsRes> => {
  let response;
  try {
    response = await API.get("api", "/accounts", {
      headers: {
        Authorization: `${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  } catch (e) {
    console.log(e);
    if (e === "No current user") {
      Auth.signOut();
    } else {
      throw new Error("API error");
    }
  }
  return response;
};

export default getAccounts;
