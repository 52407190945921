import { Box, Loader } from "@mantine/core";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import "reactflow/dist/style.css";

import getOrgHierarchy from "../api/getOrgHierarchy";
import OrgChartFlow from "../components/data/OrgChartFlow";
import LoaderData from "../types/LoaderData";
import GetOrgHierarchyRes from "../types/responses/GetOrgHierarchyRes";

export const loader = async () => {
  try {
    const responsePromise: Promise<GetOrgHierarchyRes> = getOrgHierarchy();
    return defer({ body: responsePromise });
  } catch (error) {
    throw new Error("Loader issue");
  }
};

const Visualizer = () => {
  const data = useLoaderData() as LoaderData<GetOrgHierarchyRes>;

  return (
    <Suspense fallback={<Loader />}>
      <Await resolve={data.body}>
        {(resolvedData: GetOrgHierarchyRes) => {
          return (
            <ReactFlowProvider>
              <Box h={"90vh"}>
                <OrgChartFlow res={resolvedData} />
              </Box>
            </ReactFlowProvider>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default Visualizer;
