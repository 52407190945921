import { IconUserSearch } from "@tabler/icons";

import Accounts, { loader as accountsLoader } from "../../pages/Accounts";
import RouteItem from "../../types/RouteItem";

const accountsRouteItem: RouteItem = {
  path: "accounts",
  label: "My Accounts",
  icon: <IconUserSearch stroke={1.5} />,
  element: <Accounts />,
  loader: accountsLoader,
};

export default accountsRouteItem;
