import { Box, Group, Text } from "@mantine/core";
import { Handle, HandleProps } from "reactflow";

interface BaseNodeProps {
  id?: string;
  bg?: string;
  c?: string;
  header: string;
  label: string;
  handles: HandleProps[];
}

const BaseNode = ({
  id,
  bg,
  c,
  header,
  label,
  handles,
}: BaseNodeProps): JSX.Element => {
  return (
    <>
      {handles.map((props, i) => (
        <Handle key={i} {...props} />
      ))}
      <Box
        px='md'
        pt='lg'
        pb='sm'
        bg={bg ?? "black"}
        c={c ?? "white"}
        sx={(theme) => ({
          borderRadius: theme.radius.xs,
        })}
      >
        <Group
          px={5}
          position='apart'
          pos='absolute'
          top={0}
          left={0}
          right={0}
        >
          <Text td='underline' size={"xs"}>
            {header}
          </Text>
          <Text size={"xs"}>{id}</Text>
        </Group>
        <Text>{label}</Text>
      </Box>
    </>
  );
};

export default BaseNode;
