import { IconHome } from "@tabler/icons";

import Index from "../../pages/Index";
import { loader as orgLoader } from "../../pages/Organization";
import RouteItem from "../../types/RouteItem";

export const homeRouteItem: RouteItem = {
  index: true,
  path: "",
  label: "Home Page",
  icon: <IconHome stroke={1.5} />,
  element: <Index />,
  loader: orgLoader,
};
