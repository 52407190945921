import { Group, Text, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

import DisplayCard from "./DisplayCard";

export const useStyles = createStyles((theme, _params, getRef) => {
  const icon: string = getRef("icon");
  return {
    linkIcon: {
      ref: icon,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      "&:hover": {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },
  };
});

export interface PageOverviewProps {
  link: string;
  icon: React.ReactNode;
  title: string;
  desc: string;
  linkText: string;
  docs?: {
    link: string;
    label: string;
  };
  className?: string;
}

export const PageOverviewCard = ({
  link,
  desc,
  icon,
  title,
  linkText,
  docs,
  className,
}: PageOverviewProps) => {
  const { classes } = useStyles();

  return (
    <DisplayCard
      className={className}
      header={<DisplayCard.Header icon={icon} title={title} link={link} />}
      footer={
        <Group position='apart'>
          {docs ? (
            <a
              href={docs.link}
              target='_blank'
              rel='noreferrer'
              className={classes.linkIcon}
            >
              <Text>{docs.label}</Text>
            </a>
          ) : (
            <span></span>
          )}
          <Link to={link} className={classes.linkIcon}>
            <Text>{linkText}</Text>
          </Link>
        </Group>
      }
    >
      <Text fs='italic'>{desc}</Text>
    </DisplayCard>
  );
};
