import {
  IndexRouteObject,
  NonIndexRouteObject,
  RouteObject,
} from "react-router";

import App from "../App";
import { RequireAuth } from "../components/auth/RequireAuth";
import GenericError from "../components/error/GenericError";
import { Login } from "../pages/Login";
import RouteItem from "../types/RouteItem";
import routeData from "./RouteData";

const mapRoute = (route: RouteItem): RouteObject => {
  const routeObject = {
    path: route.path,
    element: route.element,
    loader: route.loader,
    children: route.children?.map(mapRoute),
  };

  return route.index
    ? (routeObject as IndexRouteObject)
    : (routeObject as NonIndexRouteObject);
};

const mappedRoutes: RouteObject[] = routeData.map(mapRoute);

const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <RequireAuth>
        <App />
      </RequireAuth>
    ),
    errorElement: <GenericError />,
    children: [...mappedRoutes],
  },
  {
    path: "/login",
    element: <Login />,
  },
];

export default routes;
