import { NodeProps, Position } from "reactflow";

import StackVis from "../../../types/visualizer/StackVis";
import BaseNode from "./BaseNode";

export interface OrgUnitProps extends NodeProps {
  data: {
    stack: StackVis;
  };
}

const StackNode = (props: OrgUnitProps) => {
  return (
    <>
      <BaseNode
        header='Stack'
        label={props.data.stack.name}
        bg={"yellow"}
        c={"black"}
        handles={[{ type: "target", position: Position.Left }]}
      />
    </>
  );
};

export default StackNode;
