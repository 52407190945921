import { IconUsers } from "@tabler/icons";

import OrgUnits, { loader as ouLoader } from "../../pages/OrgUnits";
import RouteItem from "../../types/RouteItem";

const orgUnitRouteItem: RouteItem = {
  path: "organizational-units",
  label: "Org Units",
  icon: <IconUsers stroke={1.5} />,
  element: <OrgUnits />,
  loader: ouLoader,
};

export default orgUnitRouteItem;
