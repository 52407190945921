import {
  Authenticator,
  Image,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import { DefaultComponents } from "@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import "../assets/css/login.scss";
import logo from "../assets/images/logo.svg";

const components: DefaultComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign='center' padding={tokens.space.large}>
        <Image alt='Trek10 Logo' src={logo} />
      </View>
    );
  },
};

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const from: string = location.state?.from?.pathname || "/";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return <Authenticator hideSignUp components={components}></Authenticator>;
}
