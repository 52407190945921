import { MantineProvider, MantineThemeOverride } from "@mantine/core";

import trek10Theme from "./Trek10Theme";

type ThemeProps = {
  theme?: MantineThemeOverride;
  withCSSVariables?: boolean;
  withGlobalStyles?: boolean;
  withNormalizeCSS?: boolean;
  children: JSX.Element;
};

const Trek10ThemeProvider: React.FC<ThemeProps> = ({
  theme,
  withCSSVariables = true,
  withGlobalStyles = true,
  withNormalizeCSS = true,
  children,
}) => {
  const customTheme: MantineThemeOverride = { ...trek10Theme, ...theme };

  return (
    <MantineProvider
      theme={customTheme}
      withCSSVariables={withCSSVariables}
      withGlobalStyles={withGlobalStyles}
      withNormalizeCSS={withNormalizeCSS}
    >
      {children}
    </MantineProvider>
  );
};

export default Trek10ThemeProvider;
