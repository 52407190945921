import { ScrollArea, Table, createStyles } from "@mantine/core";
import { useEffect, useRef, useState } from "react";

const useStyles = createStyles((theme) => ({
  scrollArea: {
    border: `solid 1px ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
  header: {
    position: "sticky",
    top: 0,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",
    zIndex: 100,

    "&::after": {
      content: "''",
      position: "absolute",
      right: 0,
      left: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

interface TableScrollAreaProps {
  headers: string[];
  rows: React.ReactNode[];
}

export function DataTable({ headers, rows }: TableScrollAreaProps) {
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);

  const [height, setHeight] = useState<number>(0);

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    setHeight(tableRef.current?.clientHeight ?? 0);
  }, []);

  return (
    <ScrollArea
      h={`min(50vh, ${height + 2}px)`}
      className={classes.scrollArea}
      type='hover'
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
    >
      <Table ref={tableRef} miw={"100%"} highlightOnHover>
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            {headers.map((h, i) => (
              <th key={i}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}
