import {
  Button,
  Container,
  Group,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { Link, useRouteError } from "react-router-dom";

import RouteError from "../../types/RouteError";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: "center",
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[4]
        : theme.colors.gray[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: "auto",
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

export default function NotFoundTitle() {
  const { classes } = useStyles();

  const error: RouteError = useRouteError() as RouteError;
  console.error(error);

  return (
    <Container className={classes.root}>
      <div className={classes.label}>{error.status || error.message}</div>
      <Title className={classes.title}>
        {error.statusText || error.message}
      </Title>
      <Text
        color='dimmed'
        size='lg'
        align='center'
        className={classes.description}
      >
        {error.data}
      </Text>
      <Group position='center'>
        <Link to={"/"}>
          <Button variant='subtle' size='md'>
            Take me back to home page
          </Button>
        </Link>
      </Group>
    </Container>
  );
}
