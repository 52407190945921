import { IconLock } from "@tabler/icons";

import SCPs, { loader as scpLoader } from "../../pages/Scps";
import RouteItem from "../../types/RouteItem";

const scpRouteItem: RouteItem = {
  path: "scps",
  label: "My SCPs",
  icon: <IconLock stroke={1.5} />,
  element: <SCPs />,
  loader: scpLoader,
};

export default scpRouteItem;
