import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  AppShell,
  Breadcrumbs,
  Group,
  ScrollArea,
  createStyles,
} from "@mantine/core";
import { IconHome } from "@tabler/icons";
import { Amplify } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import "./App.scss";
import ActionMenu from "./components/body/ActionMenu";
import Headbar from "./components/body/Headbar";
import Sidebar from "./components/body/Sidebar";
import routeData, { getRouteData } from "./routing/RouteData";
import ActionItem from "./types/ActionItem";
import BreadCrumb from "./types/BreadCrumb";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: `${
        process.env.REACT_APP_COGNITO_DOMAIN +
        ".auth." +
        process.env.REACT_APP_AWS_REGION +
        ".amazoncognito.com"
      }`,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
      responseType: "token",
    },
    federationTarget: "COGNITO_USER_POOLS",
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_AWS_REGION,
      },
    ],
  },
});

const useStyles = createStyles((theme, _params, getRef) => {
  const icon: string = getRef("icon");

  return {
    main: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.white,
    },
    contentArea: {
      flexGrow: 1,
      width: "100%",
    },
    linkIcon: {
      ref: icon,
      textDecoration: "none",
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[2]
          : theme.colors.gray[6],
      "&:hover": {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === "dark" ? theme.white : theme.black,
        },
      },
    },
  };
});

function App() {
  const { classes } = useStyles();

  const location = useLocation();

  const [header, setHeader] = useState<string>("");
  const [actions, setActions] = useState<ActionItem[]>();
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);

  useEffect(() => {
    const updateRouteInfo = (label: string, actions: ActionItem[] = []) => {
      const title = ["Account Manager", label].filter((s) => s).join(" / ");

      document.title = title;
      setHeader(label);
      setActions(actions);
    };

    try {
      const { route, breadCrumbs } = getRouteData(location.pathname);
      updateRouteInfo(route?.label ?? "", route.actions);
      setBreadCrumbs(breadCrumbs);
    } catch (error) {
      document.title = "Account Manager";
      setBreadCrumbs([{ label: "Account Manager", route: "/" }]);
      updateRouteInfo("Account Manager", []);
    }
  }, [location]);

  return (
    <AppShell
      className={classes.main}
      header={<Headbar title={header} />}
      navbar={<Sidebar navItems={routeData} />}
      zIndex={999}
    >
      <Group pb={"md"} position='apart' align='center'>
        <Breadcrumbs>
          <Link to={"/"} className={classes.linkIcon}>
            <IconHome stroke={1.5} />
          </Link>
          {breadCrumbs?.map((crumb, index) => (
            <Link to={crumb.route} key={index} className={classes.linkIcon}>
              {crumb.label}
            </Link>
          ))}
        </Breadcrumbs>
        {actions?.length ? <ActionMenu actions={actions} /> : <></>}
      </Group>
      <ScrollArea className={classes.contentArea}>
        <Outlet />
      </ScrollArea>
    </AppShell>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
