import { MantineThemeOverride } from "@mantine/core";

const trek10Theme: MantineThemeOverride = {
  fontFamily: "Open Sans",
  colorScheme: "dark",
  primaryColor: "cavern",
  primaryShade: 6,
  colors: {
    stream: [
      "#e7f3ff",
      "#c8daeb",
      "#a8c0d8",
      "#87a6c5",
      "#668db3",
      "#4c7399",
      "#395a78",
      "#274057",
      "#142637",
      "#000e19",
    ],
    spring: [
      "#e5f3ff",
      "#c7daea",
      "#a8bfd7",
      "#87a6c4",
      "#668cb2",
      "#4d7399",
      "#3b5978",
      "#284057",
      "#152637",
      "#000e19",
    ],
    depth: [
      "#e7f2ff",
      "#c7d6eb",
      "#a6bad8",
      "#849ec7",
      "#6283b6",
      "#49699d",
      "#38527b",
      "#273a59",
      "#152338",
      "#020c19",
    ],
    cavern: [
      "#e9f1ff",
      "#c7d6ec",
      "#a4bada",
      "#819eca",
      "#5e83bb",
      "#4569a1",
      "#35527e",
      "#253a5b",
      "#142338",
      "#010d18",
    ],
  },
};

export default trek10Theme;
