import { NodeProps, Position } from "reactflow";

import OrgUnitVis from "../../../types/visualizer/OrgUnitVis";
import BaseNode from "./BaseNode";

export interface OrgUnitProps extends NodeProps {
  data: {
    orgUnit: OrgUnitVis;
  };
}

const OrgUnitNode = (props: OrgUnitProps) => (
  <BaseNode
    header='Org Unit'
    label={props.data?.orgUnit.name}
    bg={"blue"}
    handles={[
      { type: "target", position: Position.Left },
      { type: "source", position: Position.Right },
    ]}
  />
);

export default OrgUnitNode;
