import { Group, Header, Image, Text, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";

const useStyles = createStyles((theme) => {
  return {
    brandLink: {
      display: "flex",
      flexWrap: "nowrap",
      alignContent: "center",
      gap: "1rem",
      textDecoration: "none",
      color: "inherit",
      "&:hover": {
        color: theme.colorScheme === "dark" ? theme.white : theme.black,
      },
    },
  };
});

type Props = {
  title: string;
};

const Headbar = ({ title }: Props) => {
  const { classes } = useStyles();

  return (
    <Header height={60}>
      <Group h={60} px={"xl"} align={"center"} position='apart'>
        <Link to={"/"} className={classes.brandLink}>
          <div>
            <Image src={logo} height={"30px"} />
          </div>
        </Link>
        <Text fw={700} size={"xl"} ta={"center"} style={{ flexGrow: 1 }}>
          {title}
        </Text>
      </Group>
    </Header>
  );
};

export default Headbar;
