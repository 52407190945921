import BreadCrumb from "../types/BreadCrumb";
import RouteItem from "../types/RouteItem";
import accountsRouteItem from "./routes/accountsRouteItem";
import { homeRouteItem } from "./routes/homeRouteItem";
import orgHierarchyRouteItem from "./routes/orgHierarchyRouteItem";
import orgUnitRouteItem from "./routes/orgUnitRouteItem";
import organzationRouteItem from "./routes/organizationRouteItem";
import scpRouteItem from "./routes/scpRouteItem";

const routeData: RouteItem[] = [
  homeRouteItem,
  organzationRouteItem,
  accountsRouteItem,
  orgUnitRouteItem,
  scpRouteItem,
  orgHierarchyRouteItem,
];

interface RouteMetaData {
  route: RouteItem;
  breadCrumbs: BreadCrumb[];
}

export const getRouteData = (path: string): RouteMetaData => {
  const pathComponents = path.split("/").filter((i) => i);
  const breadCrumbs: BreadCrumb[] = [];

  let breadCrumbPath = "";

  let route: RouteItem | undefined;
  for (let i = 0; i < pathComponents.length; i++) {
    const data = route?.children ?? routeData;
    const element = pathComponents[i];
    route = data.find((r) => r.path === element);

    breadCrumbPath += `/${route?.path}`;

    breadCrumbs.push({
      label: route?.label ?? "",
      route: breadCrumbPath,
    });
  }

  if (route) {
    return {
      route,
      breadCrumbs,
    };
  }

  throw new Error(`Invalid path: ${path}`);
};

export default routeData;
