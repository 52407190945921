import Organizations, {
  loader as orgLoader,
} from "../../../pages/Organization";
import RouteItem from "../../../types/RouteItem";

const indexRouteItem: RouteItem = {
  index: true,
  element: <Organizations />,
  path: "",
  loader: orgLoader,
};

export default indexRouteItem;
