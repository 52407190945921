import { useState } from "react";
import { NodeProps, Position } from "reactflow";

import RootVis from "../../../types/visualizer/RootVis";
import BaseNode from "./BaseNode";

export interface RootNodeProps extends NodeProps {
  data: {
    root: RootVis;
  };
}

const RootNode = (props: RootNodeProps) => {
  const [root] = useState<RootVis>(props.data?.root);

  return (
    <BaseNode
      header='Org'
      label={root.id}
      handles={[{ type: "source", position: Position.Right }]}
    />
  );
};

export default RootNode;
