import { API, Auth } from "aws-amplify";

import GetScpRes from "../types/responses/GetScpRes";

const getSCPs = async (): Promise<GetScpRes> => {
  let response;
  try {
    response = await API.get("api", "/scps", {
      headers: {
        Authorization: `${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  } catch (e) {
    console.log(e);
    if (e === "No current user") {
      Auth.signOut();
    } else {
      throw new Error("API error");
    }
  }
  return response;
};

export default getSCPs;
